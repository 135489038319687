import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FormProvider } from './context/FormContext';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Press from './components/Press';
import Contact from './components/Contact';
import QuestionnaireForm from './components/QuestionnaireForm';
import PDFViewer from './components/PDFViewer';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <FormProvider>
        <div className="App flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow pt-20">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/press" element={<Press />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/forms" element={<QuestionnaireForm />} />
              <Route path="/preview" element={<PDFViewer />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </FormProvider>
    </Router>
  );
}

export default App;
