import React from 'react';
import heroImage from '../assets/hero-image.jpg';

const Hero = () => {
  return (
    <div className="container mx-auto flex items-center justify-center h-screen px-8">
      <div className="flex flex-col md:flex-row items-center justify-center max-w-6xl w-full">
        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
          <img src={heroImage} alt="Asim Ghafoor" className="rounded-lg shadow-xl max-w-full h-auto" />
        </div>
        <div className="md:w-1/2 md:pl-8">
          <h2 className="text-4xl font-serif mb-4 text-white text-center md:text-left">WHO IS ASIM GHAFOOR?</h2>
          <p className="text-lg text-white leading-snug">
            Asim Ghafoor is the Principal of G | LAW & Co., a full-service law firm based in Washington-DC. 
            With over two decades of experience, Mr. Ghafoor has advised public policymakers and clients on 
            various legal, political, and investment issues. He is a respected expert in international law, 
            corporate matters, and government relations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
