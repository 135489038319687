// import React, { useState, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FormContext } from '../context/FormContext';
// import { generateTrustDocument } from '../utils/api';
// import ProgressBar from './ProgressBar';
// import PDFViewer from './PDFViewer';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// const questions = [
//   {
//     id: 'familyTrust',
//     question: 'What is the name of your Family Trust?',
//     type: 'text',
//     placeholder: 'e.g., Smith Family Trust',
//     helpText: 'This will be the official name of your trust.'
//   },
//   {
//     id: 'dateMade',
//     question: 'What is the date of trust creation?',
//     type: 'datepicker',
//     helpText: 'This is the date your trust becomes effective.'
//   },
//   {
//     id: 'grantors',
//     question: 'Who are the grantors of your trust? (1-2 grantors)',
//     type: 'dynamic',
//     minEntries: 1,
//     maxEntries: 2,
//     helpText: 'List all individuals who are creating this trust (1-2 grantors).',
//     addButtonText: 'Add Grantor',
//     fields: [
//       { name: 'fullName', label: 'Full Name', type: 'text' },
//       { name: 'gender', label: 'Gender', type: 'select', options: ['Male', 'Female', 'Other'] }
//     ]
//   },
//   {
//     id: 'county',
//     question: 'In which county do you reside?',
//     type: 'text',
//     placeholder: 'e.g., Los Angeles',
//     helpText: 'This should be your primary county of residence.'
//   },
//   {
//     id: 'state',
//     question: 'In which state do you reside?',
//     type: 'text',
//     placeholder: 'e.g., Virginia, VA',
//     helpText: 'Enter the full name or abbreviation of your state of residence.'
//   },
//   {
//     id: 'beneficiaries',
//     question: 'Who are the beneficiaries of your trust? (1-50 beneficiaries)',
//     type: 'dynamic',
//     minEntries: 1,
//     maxEntries: 50,
//     helpText: 'List all individuals or entities who will benefit from your trust (1-50 beneficiaries).',
//     fields: [
//       { name: 'fullName', label: 'Full Name', type: 'text' },
//       { name: 'birthDate', label: 'Date of Birth', type: 'date' },
//       { name: 'relationship', label: 'Relationship to Grantor', type: 'text' }
//     ]
//   },
//   {
//     id: 'trustees',
//     question: 'Who are the trustees of your trust? (1-5 trustees)',
//     type: 'dynamic',
//     minEntries: 1,
//     maxEntries: 5,
//     helpText: 'List all individuals who will manage the trust (1-5 trustees).',
//     fields: [
//       { name: 'fullName', label: 'Full Name', type: 'text' },
//       { name: 'relationship', label: 'Relationship to Grantor', type: 'text' }
//     ]
//   },
//   {
//     id: 'assets',
//     question: 'What assets do you want to include in your trust?',
//     type: 'dynamic',
//     helpText: 'List all assets you want to transfer to the trust.',
//     fields: [
//       { name: 'assetType', label: 'Asset Type', type: 'select', options: [
//         'Cash', 'Investment Account', 'Retirement Account', 'Bank Account', 
//         'Real Estate', 'Vehicle', 'Jewelry', 'Gold', 'Life Insurance', 'Other'
//       ]},
//       { name: 'description', label: 'Description', type: 'text' },
//       { name: 'estimatedValue', label: 'Estimated Value', type: 'number' },
//       { name: 'accountNumber', label: 'Account Number (if applicable)', type: 'text' },
//       { name: 'policyNumber', label: 'Policy Number (if applicable)', type: 'text' },
//       { name: 'insuranceCompany', label: 'Insurance Company (if applicable)', type: 'text' }
//     ]
//   }
// ];

// const QuestionnaireForm = () => {
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const { formData, updateFormData } = useContext(FormContext);
//   const [pdfUrl, setPdfUrl] = useState(null);
//   const navigate = useNavigate();

//   const handleInputChange = (value) => {
//     updateFormData(questions[currentQuestion].id, value);
//   };

//   const handleDynamicInputChange = (index, field, value) => {
//     const currentData = formData[questions[currentQuestion].id] || [];
//     const updatedData = [...currentData];
//     updatedData[index] = { ...updatedData[index], [field]: value };
//     updateFormData(questions[currentQuestion].id, updatedData);
//   };

//   const handleNext = () => {
//     if (currentQuestion < questions.length - 1) {
//       setCurrentQuestion(currentQuestion + 1);
//     } else {
//       handleSubmit();
//     }
//   };

//   const handlePrevious = () => {
//     if (currentQuestion > 0) {
//       setCurrentQuestion(currentQuestion - 1);
//     }
//   };

//   const handleSubmit = async () => {
//     try {
//       const response = await generateTrustDocument(formData);
//       setPdfUrl(response.pdfUrl);
//       navigate('/preview', { state: { pdfUrl: response.pdfUrl } });
//     } catch (error) {
//       console.error('Error generating document:', error);
//       // Handle error (e.g., show error message to user)
//     }
//   };

//   const addDynamicField = () => {
//     const currentData = formData[questions[currentQuestion].id] || [];
//     updateFormData(questions[currentQuestion].id, [...currentData, {}]);
//   };

//   const removeDynamicField = (index) => {
//     const currentData = formData[questions[currentQuestion].id] || [];
//     const updatedData = currentData.filter((_, i) => i !== index);
//     updateFormData(questions[currentQuestion].id, updatedData);
//   };

//   const renderQuestion = () => {
//     const currentQ = questions[currentQuestion];

//     if (currentQ.type === 'datepicker' || (currentQ.type === 'dynamic' && currentQ.fields.some(f => f.type === 'date'))) {
//       return (
//         <div>
//           {currentQ.type === 'datepicker' ? (
//             <DatePicker
//               selected={formData[currentQ.id] ? new Date(formData[currentQ.id]) : null}
//               onChange={(date) => handleInputChange(date ? date.toISOString().split('T')[0] : null)}
//               dateFormat="MMMM d, yyyy"
//               placeholderText="Select a date"
//               className="w-full p-2 border rounded"
//               calendarClassName="bg-white shadow-lg rounded"
//             />
//           ) : (
//             <div>
//               {(formData[currentQ.id] || []).map((item, index) => (
//                 <div key={index} className="mb-4 p-4 border rounded">
//                   {currentQ.fields.map(field => (
//                     <div key={field.name} className="mb-2">
//                       <label className="block text-sm font-medium text-gray-700">{field.label}</label>
//                       {field.type === 'date' ? (
//                         <DatePicker
//                           selected={item[field.name] ? new Date(item[field.name]) : null}
//                           onChange={(date) => handleDynamicInputChange(index, field.name, date ? date.toISOString().split('T')[0] : null)}
//                           dateFormat="MMMM d, yyyy"
//                           placeholderText="Select a date"
//                           className="w-full p-2 border rounded"
//                           calendarClassName="bg-white shadow-lg rounded"
//                         />
//                       ) : (
//                         <input
//                           type={field.type}
//                           value={item[field.name] || ''}
//                           onChange={(e) => handleDynamicInputChange(index, field.name, e.target.value)}
//                           className="mt-1 block w-full p-2 border rounded"
//                         />
//                       )}
//                     </div>
//                   ))}
//                   {(formData[currentQ.id] || []).length > currentQ.minEntries && (
//                     <button
//                       onClick={() => removeDynamicField(index)}
//                       className="mt-2 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
//                     >
//                       Remove
//                     </button>
//                   )}
//                 </div>
//               ))}
//               {(formData[currentQ.id] || []).length < currentQ.maxEntries && (
//                 <button
//                   onClick={addDynamicField}
//                   className="mt-2 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
//                 >
//                   {currentQ.addButtonText || `Add ${currentQ.id.slice(0, -1)}`}
//                 </button>
//               )}
//             </div>
//           )}
//         </div>
//       );
//     }

//     switch (currentQ.type) {
//       case 'text':
//       case 'number':
//         return (
//           <input
//             type={currentQ.type}
//             value={formData[currentQ.id] || ''}
//             onChange={(e) => handleInputChange(e.target.value)}
//             placeholder={currentQ.placeholder}
//             className="w-full p-2 border rounded"
//           />
//         );
//       case 'select':
//         return (
//           <select
//             value={formData[currentQ.id] || ''}
//             onChange={(e) => handleInputChange(e.target.value)}
//             className="w-full p-2 border rounded"
//           >
//             <option value="">Select an option</option>
//             {currentQ.options.map((option) => (
//               <option key={option} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         );
//       case 'dynamic':
//         const dynamicData = formData[currentQ.id] || [];
//         return (
//           <div>
//             {dynamicData.map((item, index) => (
//               <div key={index} className="mb-4 p-4 border rounded">
//                 {currentQ.fields.map((field) => (
//                   <div key={field.name} className="mb-2">
//                     <label className="block text-sm font-medium text-gray-700">
//                       {field.label}
//                     </label>
//                     {field.type === 'select' ? (
//                       <select
//                         value={item[field.name] || ''}
//                         onChange={(e) => handleDynamicInputChange(index, field.name, e.target.value)}
//                         className="mt-1 block w-full p-2 border rounded"
//                       >
//                         <option value="">Select an option</option>
//                         {field.options.map((option) => (
//                           <option key={option} value={option}>
//                             {option}
//                           </option>
//                         ))}
//                       </select>
//                     ) : (
//                       <input
//                         type={field.type}
//                         value={item[field.name] || ''}
//                         onChange={(e) => handleDynamicInputChange(index, field.name, e.target.value)}
//                         className="mt-1 block w-full p-2 border rounded"
//                       />
//                     )}
//                   </div>
//                 ))}
//                 {dynamicData.length > currentQ.minEntries && (
//                   <button
//                     onClick={() => removeDynamicField(index)}
//                     className="mt-2 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
//                   >
//                     Remove
//                   </button>
//                 )}
//               </div>
//             ))}
//             {dynamicData.length < currentQ.maxEntries && (
//               <button
//                 onClick={addDynamicField}
//                 className="mt-2 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
//               >
//                 {currentQ.addButtonText || `Add ${currentQ.id.slice(0, -1)}`}
//               </button>
//             )}
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="max-w-2xl mx-auto p-4">
//       <ProgressBar current={currentQuestion + 1} total={questions.length} />
//       <h2 className="text-2xl font-bold mb-4">{questions[currentQuestion].question}</h2>
//       <p className="text-gray-600 mb-4">{questions[currentQuestion].helpText}</p>
//       {renderQuestion()}
//       <div className="mt-6 flex justify-between">
//         {currentQuestion > 0 && (
//           <button
//             onClick={handlePrevious}
//             className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
//           >
//             Previous
//           </button>
//         )}
//         <button
//           onClick={handleNext}
//           className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//         >
//           {currentQuestion === questions.length - 1 ? 'Submit' : 'Next'}
//         </button>
//       </div>
//       {pdfUrl && (
//         <div className="mt-8">
//           <PDFViewer pdfUrl={pdfUrl} />
//         </div>
//       )}
//     </div>
//   );
// }

// export default QuestionnaireForm;

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormContext } from '../context/FormContext';
import { generateTrustDocument } from '../utils/api';
import ProgressBar from './ProgressBar';
import PDFViewer from './PDFViewer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const questions = [
  {
    id: 'familyTrust',
    question: 'What is the name of your Family Trust?',
    type: 'text',
    placeholder: 'e.g., Smith Family Trust',
    helpText: 'This will be the official name of your trust.',
  },
  {
    id: 'dateMade',
    question: 'What is the date of trust creation?',
    type: 'datepicker',
    helpText: 'This is the date your trust becomes effective.',
  },
  {
    id: 'grantors',
    question: 'Who are the grantors of your trust? (1-2 grantors)',
    type: 'dynamic',
    minEntries: 1,
    maxEntries: 2,
    helpText: 'List all individuals who are creating this trust (1-2 grantors).',
    addButtonText: 'Add Grantor',
    fields: [
      { name: 'fullName', label: 'Full Name', type: 'text' },
      { name: 'gender', label: 'Gender', type: 'select', options: ['Male', 'Female', 'Other'] },
    ],
  },
  {
    id: 'county',
    question: 'In which county do you reside?',
    type: 'text',
    placeholder: 'e.g., Los Angeles',
    helpText: 'This should be your primary county of residence.',
  },
  {
    id: 'state',
    question: 'In which state do you reside?',
    type: 'text',
    placeholder: 'e.g., Virginia, VA',
    helpText: 'Enter the full name or abbreviation of your state of residence.',
  },
  {
    id: 'beneficiaries',
    question: 'Who are the beneficiaries of your trust? (1-50 beneficiaries)',
    type: 'dynamic',
    minEntries: 1,
    maxEntries: 50,
    helpText: 'List all individuals or entities who will benefit from your trust (1-50 beneficiaries).',
    fields: [
      { name: 'fullName', label: 'Full Name', type: 'text' },
      { name: 'birthDate', label: 'Date of Birth', type: 'date' },
      { name: 'relationship', label: 'Relationship to Grantor', type: 'text' },
    ],
  },
  {
    id: 'trustees',
    question: 'Who are the trustees of your trust? (1-5 trustees)',
    type: 'dynamic',
    minEntries: 1,
    maxEntries: 5,
    helpText: 'List all individuals who will manage the trust (1-5 trustees).',
    fields: [
      { name: 'fullName', label: 'Full Name', type: 'text' },
      { name: 'relationship', label: 'Relationship to Grantor', type: 'text' },
    ],
  },
  {
    id: 'assets',
    question: 'What assets do you want to include in your trust?',
    type: 'dynamic',
    helpText: 'List all assets you want to transfer to the trust.',
    fields: [
      { name: 'assetType', label: 'Asset Type', type: 'select', options: [
        'Cash', 'Investment Account', 'Retirement Account', 'Bank Account',
        'Real Estate', 'Vehicle', 'Jewelry', 'Gold', 'Life Insurance', 'Other',
      ]},
      { name: 'description', label: 'Description', type: 'text' },
      { name: 'estimatedValue', label: 'Estimated Value', type: 'number' },
      { name: 'accountNumber', label: 'Account Number (if applicable)', type: 'text' },
      { name: 'policyNumber', label: 'Policy Number (if applicable)', type: 'text' },
      { name: 'insuranceCompany', label: 'Insurance Company (if applicable)', type: 'text' },
    ],
  },
];

const QuestionnaireForm = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const { formData, updateFormData } = useContext(FormContext);
  const [pdfUrl, setPdfUrl] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (value) => {
    updateFormData(questions[currentQuestion].id, value);
  };

  const handleDynamicInputChange = (index, field, value) => {
    const currentData = formData[questions[currentQuestion].id] || [];
    const updatedData = [...currentData];
    updatedData[index] = { ...updatedData[index], [field]: value };
    updateFormData(questions[currentQuestion].id, updatedData);
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await generateTrustDocument(formData);
      setPdfUrl(response.pdfUrl);
      navigate('/preview', { state: { pdfUrl: response.pdfUrl } });
    } catch (error) {
      console.error('Error generating document:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const addDynamicField = () => {
    const currentData = formData[questions[currentQuestion].id] || [];
    updateFormData(questions[currentQuestion].id, [...currentData, {}]);
  };

  const removeDynamicField = (index) => {
    const currentData = formData[questions[currentQuestion].id] || [];
    const updatedData = currentData.filter((_, i) => i !== index);
    updateFormData(questions[currentQuestion].id, updatedData);
  };

  const renderQuestion = () => {
    const currentQ = questions[currentQuestion];

    if (currentQ.type === 'datepicker' || (currentQ.type === 'dynamic' && currentQ.fields.some((f) => f.type === 'date'))) {
      return (
        <div>
          {currentQ.type === 'datepicker' ? (
            <DatePicker
              selected={formData[currentQ.id] ? new Date(formData[currentQ.id]) : null}
              onChange={(date) => handleInputChange(date ? date.toISOString().split('T')[0] : null)}
              dateFormat="MMMM d, yyyy"
              placeholderText="Select a date"
              className="w-full p-2 border rounded"
              calendarClassName="bg-white shadow-lg rounded"
            />
          ) : (
            <div>
              {(formData[currentQ.id] || []).map((item, index) => (
                <div key={index} className="mb-4 p-4 border rounded">
                  {currentQ.fields.map((field) => (
                    <div key={field.name} className="mb-2">
                      <label className="block text-sm font-medium text-gray-700">
                        {field.label}
                      </label>
                      {field.type === 'date' ? (
                        <DatePicker
                          selected={item[field.name] ? new Date(item[field.name]) : null}
                          onChange={(date) =>
                            handleDynamicInputChange(index, field.name, date ? date.toISOString().split('T')[0] : null)
                          }
                          dateFormat="MMMM d, yyyy"
                          placeholderText="Select a date"
                          className="w-full p-2 border rounded"
                          calendarClassName="bg-white shadow-lg rounded"
                        />
                      ) : (
                        <input
                          type={field.type}
                          value={item[field.name] || ''}
                          onChange={(e) => handleDynamicInputChange(index, field.name, e.target.value)}
                          className="mt-1 block w-full p-2 border rounded"
                        />
                      )}
                    </div>
                  ))}
                  {(formData[currentQ.id] || []).length > currentQ.minEntries && (
                    <button
                      onClick={() => removeDynamicField(index)}
                      className="mt-2 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              {(formData[currentQ.id] || []).length < currentQ.maxEntries && (
                <button
                  onClick={addDynamicField}
                  className="mt-2 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  {currentQ.addButtonText || `Add ${currentQ.id.slice(0, -1)}`}
                </button>
              )}
            </div>
          )}
        </div>
      );
    }

    switch (currentQ.type) {
      case 'text':
      case 'number':
        return (
          <input
            type={currentQ.type}
            value={formData[currentQ.id] || ''}
            onChange={(e) => handleInputChange(e.target.value)}
            placeholder={currentQ.placeholder}
            className="w-full p-2 border rounded"
          />
        );
      case 'select':
        return (
          <select
            value={formData[currentQ.id] || ''}
            onChange={(e) => handleInputChange(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select an option</option>
            {currentQ.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case 'dynamic':
        const dynamicData = formData[currentQ.id] || [];
        return (
          <div>
            {dynamicData.map((item, index) => (
              <div key={index} className="mb-4 p-4 border rounded">
                {currentQ.fields.map((field) => (
                  <div key={field.name} className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">
                      {field.label}
                    </label>
                    {field.type === 'select' ? (
                      <select
                        value={item[field.name] || ''}
                        onChange={(e) => handleDynamicInputChange(index, field.name, e.target.value)}
                        className="mt-1 block w-full p-2 border rounded"
                      >
                        <option value="">Select an option</option>
                        {field.options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={field.type}
                        value={item[field.name] || ''}
                        onChange={(e) => handleDynamicInputChange(index, field.name, e.target.value)}
                        className="mt-1 block w-full p-2 border rounded"
                      />
                    )}
                  </div>
                ))}
                {dynamicData.length > currentQ.minEntries && (
                  <button
                    onClick={() => removeDynamicField(index)}
                    className="mt-2 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            {dynamicData.length < currentQ.maxEntries && (
              <button
                onClick={addDynamicField}
                className="mt-2 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
              >
                {currentQ.addButtonText || `Add ${currentQ.id.slice(0, -1)}`}
              </button>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <ProgressBar current={currentQuestion + 1} total={questions.length} />
      <h2 className="text-2xl font-bold mb-4">{questions[currentQuestion].question}</h2>
      <p className="text-gray-600 mb-4">{questions[currentQuestion].helpText}</p>
      {renderQuestion()}
      <div className="mt-6 flex justify-between">
        {currentQuestion > 0 && (
          <button
            onClick={handlePrevious}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Previous
          </button>
        )}
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          {currentQuestion === questions.length - 1 ? 'Submit' : 'Next'}
        </button>
      </div>
      {pdfUrl && (
        <div className="mt-8">
          <PDFViewer pdfUrl={pdfUrl} />
        </div>
      )}
    </div>
  );
};

export default QuestionnaireForm;
