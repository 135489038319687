import config from '../config';

export const generateTrustDocument = async (formData) => {
  try {
    const response = await fetch(`${config.apiUrl}/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Network response was not ok');
    }

    const data = await response.json();
    return { pdfUrl: data.pdfUrl };
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
