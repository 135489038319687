import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../config';

function PDFViewer() {
  const location = useLocation();
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (location.state?.pdfUrl) {
      const fullUrl = new URL(location.state.pdfUrl, config.apiUrl).toString();
      setPdfUrl(fullUrl);
    }
  }, [location.state]);

  if (!pdfUrl) {
    return <div className="text-center text-red-500">Loading PDF...</div>;
  }

  return (
    <div className="pdf-viewer container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Generated Trust Document</h2>
      <div className="w-full h-screen">
        <iframe
          src={pdfUrl}
          title="Trust Document PDF"
          className="w-full h-full border border-gray-300 rounded"
        ></iframe>
      </div>
      <div className="mt-4">
        <a
          href={pdfUrl}
          download="trust_document.pdf"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Download PDF
        </a>
      </div>
    </div>
  );
}

export default PDFViewer;
