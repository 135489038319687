import React from 'react';
import asimImage from '../assets/asim-ghafoor.jpg';

const About = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-serif mb-8 text-center text-gray-800">About Asim Ghafoor</h2>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
            <p className="text-gray-600 mb-4">
              Asim Ghafoor brings a wealth of experience to G | LAW & Co. He is licensed to practice in 
              Maryland, the US District Courts in the District of Columbia, Colorado and Southern District 
              of Texas, and the US Courts of Appeal for the DC, 2nd and 5th Circuits.
            </p>
            <p className="text-gray-600">
              Throughout his career, Mr. Ghafoor has been a strong advocate for civil rights and international 
              law. He has represented clients in complex legal matters, including corporate law, international 
              dispute resolution, and government relations.
            </p>
          </div>
          <div className="md:w-1/2">
            <img src={asimImage} alt="Asim Ghafoor" className="rounded-lg shadow-xl max-w-full h-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;