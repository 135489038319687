import React from 'react';

const Press = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-serif mb-8 text-center text-gray-800">Press Releases</h2>
        <div className="max-w-3xl mx-auto">
          <p className="text-gray-600 mb-4">
            This page will contain press releases and news articles related to G | LAW & Co. and Asim Ghafoor.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Press;
