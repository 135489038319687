import React from 'react';
import Hero from './Hero';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import gavelBackground from '../assets/gavel-background.jpg';

const HomePage = () => {
  return (
    <div>
      <div 
        className="bg-cover bg-center min-h-screen"
        style={{ backgroundImage: `url(${gavelBackground})` }}
      >
        <div className="bg-black bg-opacity-50 min-h-screen">
          <Hero />
        </div>
      </div>
      <About />
      <Services />
      <Contact />
    </div>
  );
};

export default HomePage;
