// components/Contact.js
import React from 'react';

const Contact = () => {
  return (
    <section id="contact" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-serif mb-8 text-center text-gray-800">Contact Us</h2>
        <div className="max-w-lg mx-auto">
          <div className="bg-gray-100 p-8 rounded-lg shadow-md">
            <p className="mb-4"><strong>Phone:</strong> (202) 330-1469</p>
            <p className="mb-4"><strong>Email:</strong> asim@ghafoorlawco.com</p>
            <p className="mb-4"><strong>Address:</strong> 23465 Rock Haven Way, Suite 100, Sterling, VA 20166</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;