import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

const Header = () => {
  return (
    <header className="fixed w-full z-50 bg-white shadow-md">
      <div className="container mx-auto px-4 py-2 flex items-center justify-between">
        <img src={logo} alt="Asim Ghafoor Logo" className="h-16" />
        <nav>
          <ul className="flex space-x-6">
            <li><Link to="/" className="text-green-600 hover:text-green-800 transition duration-300 no-underline">HOME</Link></li>
            <li><Link to="/press" className="text-green-600 hover:text-green-800 transition duration-300 no-underline">PRESS</Link></li>
            <li><Link to="/contact" className="text-green-600 hover:text-green-800 transition duration-300 no-underline">CONTACT</Link></li>
            <li><Link to="/forms" className="text-green-600 hover:text-green-800 transition duration-300 no-underline">FORMS</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
